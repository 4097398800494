import React, { useState } from "react"
import { GoogleMap, useLoadScript, Marker, MarkerWithLabel, InfoWindow } from "@react-google-maps/api"
import mapStyles from "./gmapstyles"
import Container from "react-bootstrap/Container"

//bing maps: https://www.youtube.com/watch?v=GuwmD2rPpzg

import styled from "styled-components"

export default function Gmap() {
  const [open, setOpen] = useState(false)

  const mapContainerStyle = {
    width: "80vw",
    height: "50vh",
  }
  const center = {
    lat: 48.622581,
    lng: 10.24475,
  }
  const options = {
    styles: mapStyles,
    disableDefaultUI: true,
    zoomControl: true,
  }

  const { isLoaded, loadError } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  })

  if (loadError) return "error loading maps"
  if (!isLoaded) return "loading maps"

  return (
    <Wrapper>
      <GoogleMap mapContainerStyle={mapContainerStyle} center={center} zoom={12} options={options}>
        <Marker position={{ lat: 48.62515, lng: 10.27799 }} title="Mindelseden Leopardis" onClick={() => setOpen(true)} icon={{ url: "https://img.icons8.com/pastel-glyph/35/cat--v3.png" }}></Marker>
        {open && (
          <InfoWindow position={{ lat: 48.62515, lng: 10.27799 }} onCloseClick={() => setOpen(false)}>
            <div>
              <p></p>
              <p>Sachsenhauser Str. 25</p>
              <p>89537 Giengen an der Brenz,</p>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  margin: 3rem auto;

  @media (min-width: 720px) {
    width: 60%;
  }

  @media (min-width: 960px) {
    width: 50%;
  }
`

// http://www.mapa-google.pl/9-google-maps-api/118-jak-uzyskac-klucz-api
// http://www.mapa-google.pl/9-google-maps-api/119-jak-utworzyc-konto-google-maps-platform

// https://dev.to/thomasledoux1/easy-way-to-integrate-google-maps-in-react-1j6j

// 48.62533580197333, 10.27800119824524
