import React from "react"
import Layout from "../components/layout"

import Card from "react-bootstrap/Card"
import { RiPhoneFill, RiMailFill } from "react-icons/ri"

import styled from "styled-components"
import Registration from "../components/registration"

import Container from "react-bootstrap/Container"
import Gmap from "../components/googlemap"
import Title from "../components/title"
import FacebookButton from "../components/facebookbutton"

import SEO from "../components/seo"

const KontaktPage = () => {
  return (
    <Layout>
      <SEO title="Kontakt | Leopardis" />
      <main style={{ background: "#FFFEFD", marginBottom: "2rem" }}>
        <ContactData>
          <Container fluid="sm" className="mb-3 mb-sm-5">
            <Title title="Kontaktaufname" />

            <Card>
              <Card.Body>
                <Card.Title>Leopardis Mindelseden</Card.Title>
                <p>Sylwia Tometzki</p>
                <p>89537 Giengen an der Brenz</p>
                <p>Sachsenhauser Str. 25</p>
              </Card.Body>
              <Card.Footer>
                <RiPhoneFill />
                <a href="tel:015154000054" className="btn" role="button">
                  01515 4000054
                </a>
              </Card.Footer>
              <Card.Footer>
                <RiMailFill />
                <a href="mailto:leopardisde@gmail.com" className="btn" role="button">
                  leopardisde@gmail.com
                </a>
              </Card.Footer>
            </Card>
          </Container>
        </ContactData>

        <FacebookButton />

        {/* <Gmap /> */}

        <Registration />
      </main>
    </Layout>
  )
}

const ContactData = styled.div`
  margin: 0 auto;

  @media (min-width: 720px) {
    width: 60%;
  }

  @media (min-width: 960px) {
    width: 50%;
  }

  .card {
    text-align: center;

    p {
      margin: 0;
      padding: 0;
    }

    .card-footer a {
      color: #fbb034;
    }
  }

  .card-title {
    margin: 1rem auto;
    font-family: "Pacifico", cursive;
  }
`

export default KontaktPage
